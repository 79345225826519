import jquery from 'jquery'

function defineJQuery(target) {
  if (typeof target !== 'undefined' && target.$ === undefined) {
    target.$ = target.jQuery = target.jquery = jquery
  }
}

// Define jQuery in window scope
if (typeof window !== 'undefined') {
  defineJQuery(window)
}

// Define jQuery in global scope
if (typeof global !== 'undefined') {
  defineJQuery(global)
}
